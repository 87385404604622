
<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<el-form-item label="姓名:" label-width="50px" class="w-200">
					<el-input v-model="table.where.name" placeholder="" clearable />
				</el-form-item>
				<el-form-item label="手机号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.phone" placeholder="" clearable />
				</el-form-item>

				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon ml-20">搜索</el-button>
					<el-button @click="(table.where={status:5})&&$refs.table.reload()">重置</el-button>
					<download v-if="permission.includes('sys:cancel_account:export')" style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="user/exportExcelCancel" :params="exportOrderParams()" />
				</el-form-item>
			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="headimg" label="头像" show-overflow-tooltip min-width="80">
						<template slot-scope="{row}">
									<el-image style="width:25px;height:25px;" :src="row.headimg" fit="cover" :preview-src-list="[row.headimg]">
										<div slot="error" class="image-slot">
											<i class="el-icon-picture-outline"></i>
										</div>
									</el-image>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="昵称" show-overflow-tooltip min-width="160" />
					<el-table-column prop="phone" label="联系方式" show-overflow-tooltip min-width="120" />
					<el-table-column prop="sex" label="性别" show-overflow-tooltip min-width="60" />
					<el-table-column prop="home" label="认证家乡" show-overflow-tooltip min-width="160" />
					<el-table-column label="注册时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="最近登录时间" s show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="账号注销时间" s show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.cancel_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="orders" label="累计下单量(笔)" show-overflow-tooltip min-width="100" />
<!--					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">-->
<!--						<template slot-scope="{row}">-->
<!--							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false">查看</el-link>-->
<!--						</template>-->
<!--					</el-table-column>-->
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看用户详情 -->
		<el-dialog v-dialogDrag title="查看用户详情" :visible.sync="dialogTableVisibleCheck" width="700px" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="140px">
					<div style="display: flex;">
						<div style="padding-right: 60px;border-right: 1px solid #C6C6C6;">
								<el-form-item label="用户昵称：" prop="name">
									{{checkForm.name}}
								</el-form-item>
								<el-form-item label="用户头像：" prop="headimg">
									<!-- <img :src="checkForm.headimg" width="70" height="70" /> -->
									<el-image style="width:70px;height:70px;" :src="checkForm.headimg"
										fit="cover" :preview-src-list="[checkForm.headimg]"></el-image>
								</el-form-item>
								<el-form-item label="用户手机号：" prop="phone">
									{{checkForm.phone}}
								</el-form-item>
								<el-form-item label="认证家乡：" prop="home">
									{{checkForm.home}}
								</el-form-item>
								<el-form-item label="性别：" prop="sex">
									{{checkForm.sex}}
								</el-form-item>
							</div>
							<div>
								<el-form-item label="累计发单量：" prop="orders">
									{{checkForm.orders}} 笔
								</el-form-item>
								<el-form-item label="成功叫单量：" prop="success_orders">
									{{checkForm.success_orders}} 笔
								</el-form-item>
								<el-form-item label="注册时间：" prop="create_time">
									{{ checkForm.create_time*1000 | toDateString }}
								</el-form-item>
								<el-form-item label="最近活跃时间：" prop="active_time">
									{{ checkForm.active_time*1000 | toDateString }}
								</el-form-item>
								<el-form-item label="用户余额：" prop="money">
									{{checkForm.money}}
								</el-form-item>
							</div>
						</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 编辑用户 -->
		<el-dialog v-dialogDrag title="编辑用户" :visible.sync="dialogTableVisibleEdit" 
			:destroy-on-close="true" :lock-scroll="false" @closed="[editForm={},$refs.table.reload()]">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" label-width="170px" :rules="editRules">
					<el-form-item label="用户昵称：" prop="name">
						<el-input v-model="editForm.name" placeholder="" clearable style="width: 160px;" />
					</el-form-item>
					<el-form-item label="用户头像：">
						<uploadImage :limit="1" v-model="editForm.headimg"></uploadImage>
					</el-form-item>
					<el-form-item label="用户手机号" prop="phone">
						<el-input v-model="editForm.phone" placeholder="" clearable style="width: 160px;" />
					</el-form-item>
					<div>
						<el-form-item label="认证家乡：">
							<el-select v-model="editForm.pname" @change="handleChangeProv1(editForm.pname)"
								placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
								placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="性别：" prop="sex">
						<el-radio-group v-model="editForm.sex">
							<el-radio :label="1">男</el-radio>
							<el-radio :label="2">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="累计发单量：" prop="orders">
						{{editForm.orders}} 笔
					</el-form-item>
					<el-form-item label="注册时间：" prop="create_time">
						{{ editForm.create_time*1000 | toDateString }}
					</el-form-item>
					<el-form-item label="最近活跃时间：" prop="active_time">
						{{ editForm.active_time*1000 | toDateString }}
					</el-form-item>
					<el-form-item label="用户余额：" prop="money">
						{{editForm.money}}
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="dialogTableVisibleEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<!-- 紧急联系人 -->
		<el-dialog v-dialogDrag title="紧急联系人" :visible.sync="dialogTableVisibleUrgent" width="900px" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-table :data="urgentList" element-loading-text="列表正在加载中" border fit highlight-current-row style="width: 100%;">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="uname" label="用户姓名" show-overflow-tooltip />
					<el-table-column prop="uphone" label="用户电话" show-overflow-tooltip />
					<el-table-column prop="name" label="联系人姓名" show-overflow-tooltip />
					<el-table-column prop="phone" label="联系人电话" show-overflow-tooltip />
					<el-table-column label="添加时间" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
					</el-table-column>

				</el-table>
			</el-card>



		</el-dialog>

<!--    加入黑名单弹窗-->
    <el-dialog v-dialogDrag title="确认加入黑名单" :visible.sync="dialogVisiblePlans" width="800px" :destroy-on-close="true" :lock-scroll="false">
      <el-form :model="blackReason" ref="blackReason" @submit.native.prevent :rules="editRules">
        <el-form-item label="拉黑原因:" label-width="120px" prop="black_data">
          <el-input v-model="blackReason.black_data" placeholder="拉黑原因" style="width: 580px;" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisiblePlans=false">取消</el-button>
        <el-button type="primary" @click="blackData()">确认</el-button>
      </div>
    </el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import uploadImage from '@/components/uploadImage';
	import download from '@/components/Download/index'
	export default {
		name: "SysUser",
		components: {
			uploadImage,
			download
		},
		data() {
			return {
				changePro: false,
				table: {
					url: '/user/cancel_log',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}],
					name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					status: [{
						required: true,
						message: '请选择是否启用',
						trigger: 'change'
					}],
				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleEdit: false,
				dialogTableVisibleUrgent: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				pid1: '',
				cid1: '',
				aid1: '',
        dialogVisiblePlans:false,
        blackReason: {},
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})

		},


		computed: {
			...mapGetters(["permission"]),
		},

		mounted() {},
		methods: {
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				// const params = this.params
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			dropClick(command, row) {
				if (command === 'handlecheck') { // 查看用户信息
					this.handlecheck()
				} else if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'handleUrgent') { //紧急联系人
					this.handleUrgent(row)
				} else if (command === 'handleEdit') { //修改用户信息
					this.handleEdit(row)
				} else if (command === 'blacklist') {
					this.blacklist(row)

				} else if (command === 'blackData') { //拉入黑名单
          this.dialogVisiblePlans = true
          this.blackReason = row
        }
			},

			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				this.changePro = true
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
					/** 获取被选市的cid**/
					this.changePro = true
					let cid = ''
					this.cityArr.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						let data = JSON.parse(res.data)
						this.districtArr = data
						/** 选择省份清空县**/
						this.table.where.aid = ''
					})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},


			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = true
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = true
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			handleDis() {
				this.changePro = true
			},
			/**
			/**
			  *查看用户信息
			**/
			handlecheck(row) {
				this.dialogTableVisibleCheck = true
				if (row.status == 2) {
					row.status = '正常'
				} else(
					row.status = '待完善信息'
				)
				this.checkForm = row;
			},
			/**
			 *修改用户信息
			 **/
			handleEdit(row) {
				this.dialogTableVisibleEdit = true
				if (row.sex == '男') {
					row.sex = 1
				} else {
					row.sex = 2
				}
				this.editForm = row;

        if (this.editForm.pid > 0) {
          this.$http.post('/common/city_list', {
            pid: this.editForm.pid
          }).then(res => {
            let cdata = JSON.parse(res.data)
            this.cityArr = cdata
          })
        }
        if (this.editForm.cid > 0) {
          this.$http.post('/common/area_list', {
            cid: this.editForm.cid
          }).then(res => {
            let adata = JSON.parse(res.data)
            this.districtArr = adata
          })
        }

			},
			/**
			 *紧急联系人
			 **/
			handleUrgent(row) {
				this.dialogTableVisibleUrgent = true
				this.$http.post('/user/urgentList', {
					id: row.id
				}).then(res => {
					//console.log(res.data)
					if (res.data.code === 0) {
						res.data.data.forEach(function(item) {
							item.uname = row.name
							item.uphone = row.phone
						})
						this.urgentList = res.data.data


					} else {
						this.$message.error(res.data.msg);
					}
				})
			},

			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						//console.log(this.editForm)
						if (this.changePro == true) {
							this.editForm.pid = this.editForm.pname
							this.editForm.cid = this.editForm.cname
							this.editForm.aid = this.editForm.aname
							this.changePro = false
						}
						this.$http.post('/user/edit', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.dialogTableVisibleEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								if (this.editForm.sex == '1') {
									this.editForm.sex = '男'
								} else {
									this.editForm.sex = '女'
								}
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的用户吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/user/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					this.$confirm('确定要删除选中的用户吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/user/delete', {
							id: row.id
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			/**
			 * 拉入黑名单
			 */
			blacklist(row) {
				this.$confirm('确认把该用户拉入黑名单?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/user/set_blacklist', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
      // 新加加入黑名单  2022-05-07 13:59:17
      blackData() {
        const loading = this.$loading({
          lock: true
        });
        this.$http.post('/user/set_blacklist', {
          id: this.blackReason.id,
          black_data: this.blackReason.black_data
        }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.dialogVisiblePlans=false;
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      },
			/* 导出数据Excel */
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				// let name = this.table.where.name;
				// let phone = this.table.where.phone;
				// let cid = this.table.where.cid;
				// let pid = this.table.where.pid;
				// let area_id = this.table.where.area_id;
				// let page = this.table.where.area_id;
				//console.log('user data export')
				this.$http
					// .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
					.get("/user/exportExcel", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}
	/deep/.el-dialog{
		margin-top: 40px !important;
	}
</style>
